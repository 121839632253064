@font-face {
  font-family: AutoWide;
  src: url(./Audiowide-Regular.ttf) format('truetype');
}

html {
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #c80f2f;
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #c80f2f;
}

body::-webkit-scrollbar-track {
  background: #c80f2f;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid #c80f2f;
}
