@mixin devices($breakpoint) {
  @if $breakpoint == tablet {
    @media only screen and (max-width: 680px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 481px) {
      @content;
    }
  }
}

.app-container {
  width: 100%;
  position: relative;
  padding: 0 10rem;

  @include devices(tablet) {
    padding: 0 2rem;
  }

  .logo {
    font-family: AutoWide;
    color: white;
    font-size: 14rem;
    position: fixed;
    right: 13rem;
    bottom: 30rem;

    @include devices(tablet) {
      right: 2rem;
      font-size: 6rem;
      bottom: 25rem;
    }
  }

  .content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 125rem;

    @include devices(tablet) {
      width: 40rem;
    }

    .content-desc-text {
      font-size: 2.8rem;
      width: 50%;
      color: white;
      margin-top: 18rem;
      text-align: left;

      @include devices(tablet) {
        width: 80%;
        font-size: 2rem;
      }
    }
  }

  .footer-cont {
    position: fixed;
    bottom: 0rem;
    left: 8%;
    width: 85vw;
    height: 8rem;

    @include devices(tablet) {
      left: 2%;
    }

    .cont {
      display: flex;
      justify-content: space-between;

      @include devices(tablet) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .left-cont {
        display: flex;
        width: 70%;

        @include devices(tablet) {
          width: 80%;
        }
      }

      .contents {
        margin-right: 2.2rem;
        font-size: 1.5rem;
        color: black;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-weight: 600;
      }

      .right-cont {
        @include devices(tablet) {
          width: 80%;
          margin-top: 1rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1441px) {
  .app-container {
    max-width: 1440px;
    margin: 0 auto;
  }
}
